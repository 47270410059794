export const COMPANIES = [
  ...new Set([
    "Microsoft",
    "Apple Inc.",
    "Microsoft",
    "Amazon.com",
    "Alphabet Inc.",
    "Alibaba Group",
    "Facebook",
    "Tencent",
    "Tencent",
    "Alibaba Group",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "Visa",
    "Visa",
    "Johnson & Johnson",
    "Johnson & Johnson",
    "Microsoft",
    "Microsoft",
    "Microsoft",
    "Apple Inc.",
    "Apple Inc.",
    "Amazon.com",
    "Apple Inc.",
    "Microsoft",
    "Amazon.com",
    "Apple Inc.",
    "Amazon.com",
    "Alphabet Inc.",
    "Alphabet Inc.",
    "Alphabet Inc.",
    "Alphabet Inc.",
    "Amazon.com",
    "Berkshire Hathaway",
    "Facebook",
    "Berkshire Hathaway",
    "Facebook",
    "Facebook",
    "Berkshire Hathaway",
    "Facebook",
    "Alibaba Group",
    "Alibaba Group",
    "Alibaba Group",
    "Alibaba Group",
    "Berkshire Hathaway",
    "Tencent",
    "Tencent",
    "Tencent",
    "Tencent",
    "Johnson & Johnson",
    "Visa",
    "Visa",
    "JPMorgan Chase",
    "Visa",
    "Johnson & Johnson",
    "JPMorgan Chase",
    "Visa",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Microsoft",
    "Alphabet Inc.",
    "Amazon.com",
    "Amazon.com",
    "Apple Inc.",
    "Microsoft",
    "Alphabet Inc.",
    "Microsoft",
    "Amazon.com",
    "Amazon.com",
    "Microsoft",
    "Alphabet Inc.",
    "Alphabet Inc.",
    "Tencent",
    "Facebook",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "Tencent",
    "Facebook",
    "Facebook",
    "Alibaba Group",
    "Alibaba Group",
    "Alibaba Group",
    "Tencent",
    "Facebook",
    "Berkshire Hathaway",
    "Tencent",
    "Alibaba Group",
    "JPMorgan Chase",
    "JPMorgan Chase",
    "JPMorgan Chase",
    "Johnson & Johnson",
    "Johnson & Johnson",
    "ExxonMobil ",
    "Johnson & Johnson",
    "JPMorgan Chase",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Alphabet Inc.",
    "Alphabet Inc.",
    "Alphabet Inc.",
    "Alphabet Inc.",
    "Microsoft",
    "Microsoft",
    "Microsoft",
    "Microsoft",
    "Amazon.com",
    "Amazon.com",
    "Amazon.com",
    "Amazon.com",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "Facebook",
    "ExxonMobil ",
    "Johnson & Johnson",
    "Alibaba Group",
    "Tencent",
    "Johnson & Johnson",
    "Facebook",
    "Tencent",
    "Berkshire Hathaway",
    "Facebook",
    "Alibaba Group",
    "Facebook",
    "Alibaba Group",
    "JPMorgan Chase",
    "Tencent",
    "ExxonMobil ",
    "Johnson & Johnson",
    "Wells Fargo ",
    "ExxonMobil ",
    "Johnson & Johnson",
    "JPMorgan Chase",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Alphabet Inc.",
    "Alphabet Inc. ",
    "Alphabet Inc. ",
    "Alphabet Inc. ",
    "Microsoft",
    "Microsoft",
    "Microsoft",
    "Microsoft",
    "ExxonMobil ",
    "ExxonMobil ",
    "Amazon.com",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "ExxonMobil ",
    "ExxonMobil",
    "Johnson & Johnson",
    "Amazon.com",
    "Berkshire Hathaway",
    "Amazon.com",
    "General Electric ",
    "Johnson & Johnson",
    "Johnson & Johnson",
    "Johnson & Johnson",
    "Amazon.com",
    "General Electric ",
    "Facebook",
    "JPMorgan Chase",
    "Facebook",
    "Facebook ",
    "Tencent ",
    "General Electric",
    "Wells Fargo ",
    "AT&T ",
    "General Electric ",
    "Wells Fargo",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Exxon Mobil",
    "Microsoft ",
    "Google ",
    "Alphabet ",
    "Berkshire Hathaway",
    "Exxon Mobil",
    "Microsoft ",
    "Microsoft ",
    "Google",
    "Google",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "Microsoft ",
    "Berkshire Hathaway",
    "Exxon Mobil",
    "Exxon Mobil",
    "Petro China",
    "Petro China",
    "Johnson & Johnson",
    "Amazon ",
    "Wells Fargo",
    "ICBC",
    "General Electric ",
    "General Electric ",
    "Johnson & Johnson",
    "Wells Fargo",
    "China Mobile",
    "Johnson & Johnson",
    "ICBC",
    "Johnson & Johnson",
    "Novartis",
    "Wells Fargo",
    "Novartis",
    "General Electric",
    "Nestlé",
    "JPMorgan Chase",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Exxon Mobil",
    "Exxon Mobil",
    "Exxon Mobil",
    "Exxon Mobil",
    "Microsoft ",
    "Google",
    "Microsoft ",
    "Microsoft ",
    "Google",
    "Microsoft ",
    "Google",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "Berkshire Hathaway",
    "Google",
    "Johnson & Johnson",
    "Johnson & Johnson",
    "Johnson & Johnson",
    "PetroChina",
    "Wells Fargo",
    "Wells Fargo",
    "Wells Fargo",
    "Johnson & Johnson",
    "General Electric",
    "Royal Dutch Shell ",
    "General Electric",
    "Wells Fargo",
    "Hoffmann-La Roche",
    "General Electric",
    "Novartis",
    "Wal-Mart",
    "Walmart",
    "Hoffmann-La Roche",
    "Hoffmann-La Roche",
    "ICBC",
    "Apple Inc.",
    "Exxon Mobil",
    "Apple Inc.",
    "Apple Inc.",
    "Exxon Mobil",
    "Apple Inc.",
    "Exxon Mobil",
    "Exxon Mobil",
    "Berkshire Hathaway",
    "Microsoft",
    "Berkshire Hathaway",
    "Microsoft",
    "PetroChina",
    "Berkshire Hathaway",
    "Microsoft",
    "Google",
    "Wal-Mart",
    "Wal-Mart",
    "Johnson & Johnson",
    "Berkshire Hathaway",
    "General Electric",
    "Johnson & Johnson",
    "General Electric",
    "General Electric",
    "Microsoft ",
    "General Electric",
    "Wal-Mart",
    "Johnson & Johnson",
    "IBM",
    "Google",
    "Google",
    "Wal-Mart",
    "Nestlé",
    "Chevron Corporation",
    "Chevron Corporation",
    "Hoffmann-La Roche",
    "Chevron Corporation",
    "ICBC",
    "Hoffmann-La Roche",
    "Chevron Corporation",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "Exxon Mobil",
    "Exxon Mobil",
    "Exxon Mobil",
    "Exxon Mobil",
    "PetroChina",
    "PetroChina",
    "PetroChina",
    "PetroChina",
    "Microsoft",
    "Microsoft",
    "Microsoft",
    "BHP Billiton ",
    "IBM",
    "Wal-Mart",
    "Wal-Mart",
    "ICBC ",
    "ICBC",
    "IBM",
    "General Electric",
    "China Mobile",
    "Royal Dutch Shell ",
    "General Electric",
    "IBM",
    "Wal-Mart",
    "China Mobile",
    "China Mobile",
    "Chevron Corporation",
    "Samsung Electronics",
    "General Electric",
    "Royal Dutch Shell ",
    "China Mobile",
    "Microsoft",
    "Chevron Corporation",
    "ICBC ",
    "Royal Dutch Shell ",
    "Royal Dutch Shell ",
    "Exxon Mobil",
    "Exxon Mobil",
    "Apple Inc.",
    "Exxon Mobil",
    "PetroChina",
    "Apple Inc.",
    "Exxon Mobil",
    "Apple Inc.",
    "Apple Inc.",
    "PetroChina",
    "PetroChina",
    "PetroChina",
    "ICBC",
    "ICBC",
    "IBM",
    "Royal Dutch Shell",
    "Petrobras",
    "BHP Billiton",
    "Microsoft",
    "ICBC",
    "BHP Billiton",
    "Royal Dutch Shell",
    "ICBC",
    "Microsoft",
    "China Construction Bank",
    "Microsoft",
    "China Mobile",
    "IBM",
    "Royal Dutch Shell",
    "Nestlé",
    "Royal Dutch Shell",
    "Chevron Corporation",
    "Chevron Corporation",
    "Petrobras",
    "Nestlé",
    "Wal-Mart",
    "Microsoft",
    "IBM",
    "Chevron Corporation",
    "China Mobile",
    "PetroChina",
    "Exxon Mobil",
    "Exxon Mobil",
    "Exxon Mobil",
    "Exxon Mobil",
    "PetroChina",
    "PetroChina",
    "PetroChina",
    "Microsoft",
    "Apple Inc.",
    "Apple Inc.",
    "Apple Inc.",
    "ICBC",
    "ICBC",
    "Petrobras",
    "BHP Billiton",
    "Apple Inc.",
    "Microsoft",
    "ICBC",
    "Microsoft",
    "BHP Billiton",
    "China Mobile",
    "Microsoft",
    "ICBC",
    "Wal-Mart",
    "Berkshire Hathaway",
    "China Mobile",
    "Petrobras",
    "Berkshire Hathaway",
    "China Construction Bank",
    "Berkshire Hathaway",
    "China Construction Bank",
    "General Electric",
    "Wal-Mart",
    "China Construction Bank",
    "Royal Dutch Shell",
    "China Mobile",
    "Procter & Gamble",
    "BHP Billiton",
    "Nestlé",
    "Exxon Mobil",
    "PetroChina ",
    "Exxon Mobil",
    "PetroChina ",
    "PetroChina",
    "Exxon Mobil ",
    "PetroChina",
    "Exxon Mobil ",
    "Wal-Mart",
    "ICBC ",
    "ICBC ",
    "Microsoft",
    "ICBC",
    "Microsoft ",
    "Microsoft",
    "ICBC ",
    "China Mobile",
    "China Mobile ",
    "HSBC",
    "Wal-Mart ",
    "Microsoft",
    "Wal-Mart",
    "China Mobile",
    "China Construction Bank",
    "AT&T",
    "China Construction Bank",
    "Wal-Mart",
    "BHP Billiton",
    "Johnson & Johnson",
    "Petrobras",
    "Petrobras",
    "HSBC",
    "Royal Dutch Shell",
    "Johnson & Johnson",
    "China Construction Bank",
    "Petrobras",
    "Procter & Gamble",
    "Royal Dutch Shell",
    "Royal Dutch Shell",
    "Apple Inc.",
    "Exxon Mobil",
    "Exxon Mobil ",
    "Exxon Mobil",
    "Exxon Mobil ",
    "PetroChina",
    "PetroChina ",
    "PetroChina",
    "PetroChina ",
    "General Electric",
    "ICBC ",
    "ICBC ",
    "Wal-Mart",
    "Gazprom",
    "Microsoft ",
    "Microsoft",
    "China Mobile ",
    "China Mobile",
    "China Mobile ",
    "HSBC",
    "Procter & Gamble ",
    "ICBC",
    "Wal-Mart",
    "China Mobile",
    "ICBC",
    "Microsoft",
    "China Construction Bank",
    "Wal-Mart",
    "Microsoft",
    "AT&T",
    "Petrobras",
    "Petrobras",
    "AT&T",
    "Royal Dutch Shell",
    "Johnson & Johnson",
    "China Construction Bank",
    "Johnson & Johnson",
    "Procter & Gamble",
    "Royal Dutch Shell",
    "Royal Dutch Shell",
    "General Electric",
    "Exxon Mobil",
    "Exxon Mobil",
    "Exxon Mobil",
    "Petrochina",
    "General Electric",
    "General Electric",
    "General Electric",
    "Exxon Mobil ",
    "Microsoft",
    "Microsoft",
    "China Mobile ",
    "General Electric ",
    "Citigroup",
    "Royal Dutch Shell",
    "ICBC",
    "China Mobile",
    "AT&T ",
    "AT&T",
    "Microsoft",
    "ICBC",
    "Gazprom",
    "Citigroup",
    "Royal Dutch Shell",
    "Microsoft ",
    "Toyota Motor Corporation",
    "Gazprom",
    "Gazprom ",
    "Gazprom ",
    "Bank of America",
    "BP",
    "AT&T",
    "Royal Dutch Shell",
    "ICBC",
    "Toyota Motor Corporation",
    "Citigroup",
    "AT&T",
    "Royal Dutch Shell",
    "Bank of America",
    "Bank of America",
    "Sinopec",
    "Exxon Mobil",
    "Exxon Mobil",
    "Exxon Mobil",
    "Exxon Mobil",
    "General Electric",
    "General Electric",
    "General Electric",
    "General Electric",
    "Microsoft",
    "Gazprom",
    "Microsoft",
    "Microsoft",
    "Citigroup",
    "Citigroup",
    "Gazprom",
    "Citigroup",
    "BP",
    "Microsoft",
    "Citigroup",
    "Gazprom",
    "Bank of America",
    "BP",
    "Bank of America",
    "Industrial and Commercial Bank of China",
    "Royal Dutch Shell",
    "Royal Dutch Shell",
    "Royal Dutch Shell",
    "Toyota Motor Corporation",
    "Wal-Mart",
    "Bank of America",
    "BP",
    "Bank of America",
    "Toyota Motor Corporation",
    "HSBC",
    "HSBC",
    "Royal Dutch Shell",
    "Gazprom",
    "Wal-Mart",
    "Pfizer",
    "BP",
    "General Electric",
    "Exxon Mobil",
    "Microsoft",
    "Citigroup",
    "BP",
    "Wal-Mart",
    "Royal Dutch Shell",
    "Johnson & Johnson",
    "Pfizer",
    "Bank of America",
    "General Electric",
    "Microsoft",
    "Exxon Mobil",
    "Pfizer",
    "Citigroup",
    "Wal-Mart",
    "American International Group",
    "Intel Corporation",
    "BP",
    "HSBC",
    "Microsoft",
    "General Electric",
    "Exxon Mobil",
    "Wal-Mart",
    "Pfizer",
    "Citigroup",
    "Johnson & Johnson",
    "Royal Dutch Shell",
    "BP",
    "IBM",
    "General Electric",
    "Microsoft",
    "Exxon Mobil",
    "Wal-Mart",
    "Citigroup",
    "Pfizer",
    "Intel Corporation",
    "BP",
    "Johnson & Johnson",
    "Royal Dutch Shell",
    "General Electric",
    "Cisco Systems",
    "Exxon Mobil",
    "Pfizer",
    "Microsoft",
    "Wal-Mart",
    "Citigroup",
    "Vodafone",
    "Intel Corporation",
    "Royal Dutch Shell",
    "Microsoft",
    "General Electric",
    "NTT DoCoMo",
    "Cisco Systems",
    "Wal-Mart",
    "Intel Corporation",
    "Nippon Telegraph and Telephone",
    "Exxon Mobil",
    "Lucent Technologies",
    "Deutsche Telekom",
    "Microsoft",
    "General Electric",
    "Exxon Mobil",
    "Royal Dutch Shell",
    "Merck",
    "Pfizer",
    "Intel Corporation",
    "The Coca-Cola Company",
    "Wal-Mart",
    "IBM",
    "General Electric",
    "Royal Dutch Shell",
    "Microsoft",
    "Exxon Mobil",
    "The Coca-Cola Company",
    "Intel Corporation",
    "Nippon Telegraph and Telephone",
    "Merck",
    "Toyota Motor Corporation",
    "Novartis",
    "General Electric",
    "Royal Dutch Shell",
    "The Coca-Cola Company",
    "Nippon Telegraph and Telephone",
    "Exxon Mobil",
  ]),
];
