import { Search } from "components/Search";

import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { DefaultContainer } from "./DefaultContainer";
import { DefaultWrapper } from "./DefaultWrapper";

export const Header: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <DefaultWrapper>
      <DefaultContainer css={css``}>
        <HeaderContainer>
          <Link
            css={css`
              cursor: pointer;
              font-size: 36px;
              font-weight: bold;
              margin-right: 32px;
            `}
            to="/"
          >
            coverletter.ai
          </Link>
          {pathname.includes("ask") && <Search noButton />}
        </HeaderContainer>
      </DefaultContainer>
    </DefaultWrapper>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  width: 100%;
`;
