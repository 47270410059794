import { COMPANIES } from "assets/companies";
import { DefaultContainer } from "layout/DefaultContainer";
import { DefaultWrapper } from "layout/DefaultWrapper";
import { Configuration, OpenAIApi } from "openai";

import React from "react";
import { useNavigate } from "react-router";

import { css } from "@emotion/react";

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);
export const Root: React.FC = () => {
  const [company, setCompany] = React.useState(COMPANIES[0]);
  const [loading, setLoading] = React.useState(false);
  const [coverLetter, setCoverLetter] = React.useState("");
  return (
    <DefaultWrapper>
      <DefaultContainer
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <p>Write me a cover letter for</p>
        <select
          name="companies"
          value={company}
          onChange={(e) => {
            setCompany(e.target.value);
          }}
        >
          {COMPANIES.map((company, i) => (
            <option key={i} value={company}>
              {company}
            </option>
          ))}
        </select>
        <button
          onClick={async () => {
            setLoading(true);
            try {
              const response = await openai.createCompletion({
                model: "text-curie-001",
                prompt: `Write me a cover letter for ${company}`,
                temperature: 0,
                max_tokens: 2036,
                top_p: 1,
                frequency_penalty: 0.0,
                presence_penalty: 0.0,
              });
              setCoverLetter(response.data.choices[0].text?.trim() ?? "");
            } catch (e) {
              console.error(e);
            } finally {
              setLoading(false);
            }
          }}
          css={css`
            margin-top: 24px;
            width: 200px;
            height: 50px;
          `}
        >
          {loading ? "Loading..." : "Go"}
        </button>
        <p
          css={css`
            margin-top: 64px;
          `}
        >
          {coverLetter}
        </p>
      </DefaultContainer>
    </DefaultWrapper>
  );
};
